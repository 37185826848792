import styles from "./ServiceInfo.module.scss";
import {SERVICES_LIST} from "../List/Services";
import {useAppSelector} from "@/hooks/appHook";
import {getActiveService} from "@/store/services/services";
import {useIntl} from "react-intl";
import IconButton from "@/components/Primitive/Buttons/IconButton/IconButton";
import {MouseEventHandler} from "react";
import {isMobile} from "@/store/screenSize/screenSize";
import useActiveService from '@/hooks/useActiveService'

const ServiceInfo = () => {
  const activeService = useAppSelector(getActiveService)
  const serviceCurrent = SERVICES_LIST.find(item => item.name === activeService);
  const {formatMessage} = useIntl()
  const mobileView = useAppSelector(isMobile)
  const {setActiveService} = useActiveService()

  const handleBack: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setActiveService(null)
  }

  if (! activeService) {
    return <></>
  }

  return <>
    <div className={styles.header}>
      {mobileView && <IconButton
        className={styles.backButton}
        onClick={handleBack}
      >
        <i className={'chat-arrow-l'} />
      </IconButton>}
      <img className={styles.avatar} src={serviceCurrent?.icon} alt="logo"/>
      <div className={styles.infoBlock}>
        <div className={styles.title}>{formatMessage({id: `SERVICES.${serviceCurrent?.name}`})}</div>
        <div className={styles.description}>{serviceCurrent?.displayLink}</div>
      </div>
    </div>
    <div className={styles.info}>
      <img src={serviceCurrent?.icon} alt={formatMessage({id: `SERVICES.${serviceCurrent?.name}`})} />
      <h4>{formatMessage({id: `SERVICES.${serviceCurrent?.name}`})}</h4>
      <a href={serviceCurrent?.link} rel="noreferrer" target="_blank">
        {formatMessage(
          {id: `SERVICES.GO_TO_LINK`},
          {link: formatMessage({id: `SERVICES.${serviceCurrent?.name}`})
          })}
      </a>
    </div>
  </>
}

export default ServiceInfo
