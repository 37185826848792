import React from "react";
import {Scrollbars} from "react-custom-scrollbars-2";
import styles from './Services.module.scss';
import {useIntl} from "react-intl";
import {useAppSelector} from "@/hooks/appHook";
import {getActiveService} from "@/store/services/services";
import classNames from 'classnames';
import useActiveService from '@/hooks/useActiveService'

export const SERVICES_LIST = [
  {
    name: 'ADX',
    icon: '/assets/img/services/adx-app-icon.svg',
    link: 'https://adxsales.com/',
    displayLink: 'adxsales.com',
  },
  {
    name: 'INTER_WORLD',
    icon: '/assets/img/services/interworld.svg',
    link: 'https://interworld.io/',
    displayLink: 'interworld.io',
  },
  {
    name: 'ADX_CONNECT',
    icon: '/assets/img/services/connect.svg',
    link: 'https://connect.adxsales.com/',
    displayLink: 'connect.adxsales.com',
  },
  {
    name: 'CMLX',
    icon: '/assets/img/services/cmlx.svg',
    link: 'https://cmlx.com/',
    displayLink: 'cmlx.com',
  },
  {
    name: 'DISCOVER',
    icon: '/assets/img/services/discover.svg',
    link: 'https://discover.adxsales.com/',
    displayLink: 'discover.adxsales.com',
  },
  {
    name: 'ADX_TIC_TAC_TOE',
    icon: '/assets/img/services/tic-tac-toe-icon.svg',
    link: 'https://adxsales.com/en/tictactoe',
    displayLink: 'adxsales.com/en/tictactoe',
  },
  {
    name: 'ADX_MAIL',
    icon: '/assets/img/services/acesse-mail.svg',
    link: 'https://adxmail.net/',
    displayLink: 'adxmail.net',
  },
  {
    name: 'A_PLAY',
    icon: '/assets/img/services/aplay-icon.svg',
    link: 'https://aplay.net/',
    displayLink: 'aplay.net',
  },
  {
    name: 'GAME_SMART',
    icon: '/assets/img/services/game-smart.svg',
    link: 'http://gamesmart.com/',
    displayLink: 'gamesmart.com',
  },
  {
    name: 'E_WALLET',
    icon: '/assets/img/services/e-wallet.svg',
    link: 'https://acesse.globalewallet.com/',
    displayLink: 'acesse.globalewallet.com',
  },
  {
    name: 'ADX_APPS',
    icon: '/assets/img/services/app-mart-store.svg',
    link: 'https://apps.adxsales.com/en/',
    displayLink: 'apps.adxsales.com',
  },
  {
    name: 'ABEY_SHOP',
    icon: '/assets/img/services/abey-shop.svg',
    link: 'https://classifieds.adxsales.com/en/search?abeyshop_only=1',
    displayLink: 'classifieds.adxsales.com/en/search?abeyshop_only=1',
  },
  {
    name: 'CLASSIFIEDS',
    icon: '/assets/img/services/classifieds.svg',
    link: 'https://classifieds.adxsales.com/',
    displayLink: 'classifieds.adxsales.com',
  },
  {
    name: 'ADX_MOBILE_APP',
    icon: '/assets/img/services/acesse-mobile-app.svg',
    link: 'https://apps.adxsales.com/en/app/coafXHP3jwnD',
    displayLink: 'apps.adxsales.com/en/app/coafXHP3jwnD',
  },
  {
    name: 'ADX_LTD',
    icon: '/assets/img/services/adxltd.svg',
    link: 'https://adxltd.com/en/auth/login',
    displayLink: 'adxltd.com/en/auth/login',
  },
]

const Services = () => {
  const activeService = useAppSelector(getActiveService)
  const {formatMessage} = useIntl()
  const {setActiveService} = useActiveService()


  const handleActiveService = (serviceName:any) => {
    setActiveService(serviceName)
  }

  return <div className={styles.services}>
    <Scrollbars>
      <ul>
        {SERVICES_LIST.map((value) => (
          <li className={classNames(styles.service, activeService === value.name ? styles.activeService : null)}
            onClick={() => handleActiveService(value.name)}>
            <img className={styles.avatar} src={value.icon} alt="logo"/>
            <p className={styles.name}>{formatMessage({id: `SERVICES.${value.name}`})}</p>
            <p className={styles.link}>{value.displayLink}</p>
          </li>
        ))}
      </ul>
    </Scrollbars>
  </div>
}

export default Services
