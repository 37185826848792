import {authenticate} from "@/api/auth";
import { setToken} from "@/store/user/user";
import store from "@/store";

export const auth = async () => {
  const state = store.getState()
  const user = state.user.user
  if (!user?.memberId || !user.accessToken) {
    throw new Error('User not found')
  }
  const {data: userToken} = await authenticate({
    memberId: user.memberId,
    accessToken: user.accessToken
  })
  store.dispatch(setToken(userToken.token))
}
