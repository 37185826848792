import request from "./request";

export const pushApp = (id: any, type: any) => {
  let formData = {}
  if (type === 'chat') {
    formData = {
      memberId: id.split('@')[0],
      type: type,
    }
  } else if (type === 'groupchat') {
    formData = {
      chatId: id.split('@')[0],
      type: type,
    }
  } else if (type === 'friend') {
    formData = {
      memberId: id.split('@')[0],
      type: type,
    }
  }
  return request('get', '/api/user/push-notify', formData)
    .then(data => {
      return data.data
    })
}

export const pushCall = (memberId: string) => {
  return request('get', '/api/user/push-call', {
    called: memberId
  })
}
