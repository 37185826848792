import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './Profiler.module.scss'
import titleModalStyles from '../BaseModalWithTitle/BaseModalWithTitle.module.scss'
import { useAppDispatch, useAppSelector } from '@/hooks/appHook';
import { getUser, IUserVCard, IUserVCardKeys, setAdditionalFields } from '@/store/user/user';
import { BaseModal, BaseModalProps } from '@/components/Modal/BaseModal/BaseModal';
import Field from '@/components/Primitive/Field/Field';
import { ReactComponent as UserImage } from '@/images/icons/user.svg';
import { ReactComponent as EmailImage } from '@/images/icons/email.svg';
import { ReactComponent as LinkImage } from '@/images/icons/link.svg';
import { ReactComponent as BlockImage } from '@/images/icons/block.svg';
import { ReactComponent as LanguageImage } from '@/images/icons/world.svg';
import { ReactComponent as ExitImage } from '@/images/icons/exit.svg';
import { ReactComponent as FontImage } from '@/images/icons/font.svg';
import { ReactComponent as SettingsImage } from '@/images/icons/settings.svg';
import { getChatId } from '@/utils/chats';
import { useIntl } from 'react-intl';
import VCard from '@/services/VCard';
import { uploadAvatar } from '@/api/upload';
import Presence from '@/services/Presence';
import useUtils from '@/hooks/useUtils';
import PhotoEditor from '@/components/PhotoEditor/PhotoEditor';
import BlockedUsers from '@/components/Modal/BlockedUsers/BlockedUsers';
import Language from '@/components/Modal/Language/Language';
import Logout from '@/components/Modal/Logout/Logout';
import { getScreenHeight, getScreenWidth, isMobile } from '@/store/screenSize/screenSize';
import NavTabs from '@/components/NavTabs/NavTabs';
import classNames from 'classnames';
import ModalTitle from '@/components/Modal/ModalTitle/ModalTitle';
import useModalSimple from '@/hooks/useModalSimple';
import { getFirstChar } from '@/utils/text';
import SizeSelector from '@/components/SizeSelector/SizeSelector';
import { getFontScale, MAX_FONT_SCALE, MIN_FONT_SCALE, setFontScale, STEP_FONT_SCALE } from '@/store/settings/settings';
import connection from '@/services/Connection/Connection';
import Avatar from '@/components/Avatar/Avatar'
import IconButton from '@/components/Primitive/Buttons/IconButton/IconButton'
import SupportTickets from '@/components/Modal/SupportTicketsModal/SupportTickets/SupportTickets'
import useSecondaryPopupHandler from '@/hooks/useSecondaryPopupHandler'
import { getUnreadMessagesAmount as apiUnreadMessagesAmount } from '@/api/supportTickets'
import { setUnreadMessageAmount, getUnreadMessagesAmount } from '@/store/supportTickets/supportTickets'

const MAX_AVATAR_SIZE = 400
const CONTAINER_PADDINGS = 24

const Profiler = ({ hide }: BaseModalProps) => {
  const user = useAppSelector(getUser)
  const mobileView = useAppSelector(isMobile)
  const screenHeight = useAppSelector(getScreenHeight)
  const screenWidth = useAppSelector(getScreenWidth)
  const fontScale = useAppSelector(getFontScale)
  const unreadMessage = useAppSelector(getUnreadMessagesAmount)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const [hidden, setHidden] = useState(false)
  const [bigPhoto, setBigPhoto] = useState(false)
  const [allowPhotoEdit, setAllowPhotoEdit] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState(user?.thumbnail)
  const { logout } = useUtils()

  useEffect(() => {
    apiUnreadMessagesAmount()
      .then(total => {
        dispatch(setUnreadMessageAmount(total))
      })
  }, [dispatch]);

  useEffect(() => {
    const url = user?.url
    if (!url) {
      setAvatarUrl(undefined)
      return
    }
    if (bigPhoto && avatarUrl !== url) {
      const image = new Image()
      image.onload = () => {
        setAvatarUrl(url)
      }
      image.src = url
    }
  }, [bigPhoto, user?.url, avatarUrl])

  const onClickLogout = useCallback(() => {
    logout()
  }, [logout])

  const { show: showBlockedUsers, hide: hideBlockedUsers, visible: visibleBlockedUsers } = useModalSimple()
  const { show: showLanguage, hide: hideLanguage, visible: visibleLanguage } = useModalSimple()
  const { show: showLogout, hide: hideLogout, visible: visibleLogout } = useModalSimple()
  const { show: showSupportTickets, hide: hideSupportTickets, visible: visibleSupportTickets } = useModalSimple()

  const hideSecondaryList = useMemo(() => [
    hideBlockedUsers,
    hideLanguage,
    hideLogout,
    hideSupportTickets,
  ], [hideBlockedUsers, hideLanguage, hideLogout, hideSupportTickets])

  const {onBackModal, onCloseModal} = useSecondaryPopupHandler({
    hide,
    hideSecondaryList
  })

  const onCropEditorClose = useCallback(() => {
    setHidden(false)
  }, [])

  const actionBeforeCropClosed = useCallback((blob: Blob): Promise<void> => {
    return new Promise(async(res) => {
      try {
        const { url, thumbnail } = await uploadAvatar(blob)
        if (!url) {
          res()
          return
        }
        const msg = VCard.Messages.setVCard({
          ...user,
          nickname: user?.nickname || '',
          url,
          thumbnail,
        })
        await connection.send(msg)
        const notify = Presence.Messages.notifyVCardUpdated()
        connection.send(notify)
        const image = new Image()
        image.onload = () => {
          dispatch(setAdditionalFields({ url, thumbnail }))
          res()
        }
        image.onerror = () => {
          res()
        }
        image.src = url
      } catch (e) {
        console.log(e)  // eslint-disable-line no-console
        res()
      }
    })
  }, [dispatch, user])

  const deleteAvatar = useCallback(async() => {
    const fields: IUserVCard = {
      ...user,
      nickname: user?.nickname || '',
    }
    const excludedFields: IUserVCardKeys[] = ['url', 'thumbnail']
    excludedFields.forEach(key => {
      delete fields[key]
    })
    const msg = VCard.Messages.setVCard({
      ...fields,
    })
    try {
      await connection.send(msg)
      const notify = Presence.Messages.notifyVCardUpdated()
      connection.send(notify)
      dispatch(setAdditionalFields({
        url: null,
        thumbnail: null,
      }))
    } catch (e) {

    }

  }, [user, dispatch])

  const onSaveNickname = async(text: string) => {
    const msg = VCard.Messages.setVCard({
      ...user,
      nickname: text,
    })
    const notify = Presence.Messages.notifyVCardUpdated()
    const oldName = user?.nickname
    try {
      dispatch(setAdditionalFields({ nickname: text }))
      await connection.send(msg)
      connection.send(notify)
    } catch (e) {
      dispatch(setAdditionalFields({
        nickname: oldName,
      }))
    }

  }

  const toBlockedUsers = () => {
    showBlockedUsers()
  }

  const toLanguage = () => {
    showLanguage()
  }

  const toSupportTickets = () => {
    showSupportTickets()
  }

  const toLogout = () => {
    showLogout()
  }

  const onCropEditorOpened = () => {
    setHidden(true)
  }

  const handleFontScale = (value: number) => {
    dispatch(setFontScale(value))
  }

  const onPhotoAnimationEnd = () => {
    setAllowPhotoEdit(bigPhoto)
  }

  const secondaryPopupShown =
    visibleBlockedUsers ||
    visibleLanguage ||
    visibleLogout ||
    visibleSupportTickets

  return <BaseModal
    className={classNames((
      hidden ||
      secondaryPopupShown
    ) && 'visually-hidden')}
    hide={hide}
  >
    <ModalTitle
      canClose={!mobileView}
      onClose={hide}
      title={'' + user?.nickname} />
    <div
      style={{ height: mobileView ? screenHeight - 66 : undefined }}
      className={classNames(styles.container, mobileView && styles.mobile)}>
      <div
        style={{ height: mobileView ? screenHeight - 134 : undefined }}
        className={classNames(titleModalStyles.context, styles.context)}>
        <div className={styles.section}>
          <div
            className={classNames(styles.animBox, bigPhoto && styles.big, !bigPhoto && styles.active)}
            onClick={() => setBigPhoto(true)}
            onTransitionEnd={onPhotoAnimationEnd}
          >
            {!allowPhotoEdit && <Avatar
              className={classNames(styles.avatar, bigPhoto && styles.big)} src={avatarUrl}
              name={user?.nickname}
              onClick={() => setBigPhoto(true)}
            />}
            {allowPhotoEdit && <PhotoEditor
              imageSize={{
                width: screenWidth > MAX_AVATAR_SIZE + 2 * CONTAINER_PADDINGS ?
                  MAX_AVATAR_SIZE : screenWidth - 2 * CONTAINER_PADDINGS, height: 'auto',
              }}
              className={styles.photoEditor}
              url={avatarUrl}
              text={getFirstChar(user?.nickname || '').toUpperCase()}
              deletePhoto={deleteAvatar}
              canEdit={true}
              transparentModalBackground={true}
              onCropEditorOpened={onCropEditorOpened}
              onCropEditorClose={onCropEditorClose}
              actionBeforeCropClosed={actionBeforeCropClosed}
            />}
            {bigPhoto ? <Field
              className={styles.inputProfile}
              text={user?.nickname || getChatId(user?.$jid || '')}
              SvgIcon={UserImage}
              button={'edit'}
              minLength={2}
              maxLength={32}
              onSave={onSaveNickname}
            /> : <>
              <div className={styles.infoGroup}>
                <span className={styles.groupNameText}>{user?.nickname || ''}</span>
              </div>
              <IconButton
                className={styles.settingsBtn}
                onClick={() => setBigPhoto(true)}
              ><SettingsImage /></IconButton>
            </>}
          </div>
        </div>
        <Field
          text={getChatId(user?.$jid || '')}
          textIcon={'ID'}
        />
        <Field
          text={user?.email}
          SvgIcon={EmailImage} />
        <Field
          text={user?.refInfo?.link}
          SvgIcon={LinkImage}
          button={'copy'}
        />
        <Field
          text={formatMessage({ id: 'blocked_users' })}
          SvgIcon={BlockImage}
          button={'next'}
          onNext={toBlockedUsers}
        />
        <Field
          text={formatMessage({ id: 'text_size' })}
          SvgIcon={FontImage}
          highlighted={false}
        />
        <SizeSelector
          initValue={fontScale}
          textIcon={'A'}
          minValue={MIN_FONT_SCALE}
          maxValue={MAX_FONT_SCALE}
          step={STEP_FONT_SCALE}
          onChange={handleFontScale}
        />
        <Field
          text={formatMessage({ id: 'language' })}
          SvgIcon={LanguageImage}
          button={'next'}
          onNext={toLanguage}
        />
        <div className={styles.fieldBox}>
          {!!unreadMessage && <span className={styles.counter}>{unreadMessage}</span>}
          <Field
            text={formatMessage({ id: 'support_tickets' })}
            button={'next'}
            fontIcon={'chat-chat-head'}
            onNext={toSupportTickets}
          />
        </div>
        <Field
          color={'#ff3f3d'}
          text={formatMessage({ id: 'button_logout' })}
          button={'button'}
          SvgIcon={ExitImage}
          onClick={toLogout} />
      </div>
      {visibleBlockedUsers && <BlockedUsers
        inside
        canBack={!mobileView}
        canSelfClose={false}
        onBack={onBackModal}
        onClose={onCloseModal}
      />}
      {visibleLanguage && <Language
        inside
        canBack={!mobileView}
        canSelfClose={false}
        onBack={onBackModal}
        onClose={onCloseModal}
      />}
      {visibleLogout && <Logout
        inside
        canBack={!mobileView}
        canSelfClose={false}
        onBack={onBackModal}
        onClose={onCloseModal}
        onClickLogout={onClickLogout}
      />}
      {mobileView && <NavTabs
        className={styles.navTabs} />}
      {visibleSupportTickets && <SupportTickets
        inside
        canBack={!mobileView}
        canSelfClose={false}
        onBack={onBackModal}
        hide={onCloseModal}
      />}
    </div>
  </BaseModal>
}

export default Profiler
