import { createSlice } from '@reduxjs/toolkit'
import {RootState} from "@/store";
import {axiosRequest} from "@/api/axiosInit";
import { removeAccessTokenFromApp, sendAccessTokenToApp, sendTokenToApp } from '@/utils/app'

const FIELD_NAME = 'currentUser'
export interface IInitUser {
  memberId: string,
  $jid?: string,
  accessToken: string,
  token?: string,
  nickname?: string,
  thumbnail?: string,
  url?: string,
  email?: string,
  phoneNumber?: string,
  refInfo?: {
    ref: string,
    link: string
  },
  isAdmin: boolean,
  canBroadcast: boolean,
  chatReferrerLink?: string,
}

export type IUserVCard = {
  nickname: string
  thumbnail?: string
  url?: string
}

export type IUserVCardKeys = keyof IUserVCard

export const changeToken = (token: string) => {
  axiosRequest.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  sendTokenToApp(token)
}

const initUser = JSON.parse('' + localStorage.getItem(FIELD_NAME)) as (IInitUser | null)
changeToken(initUser?.token || '')

if (initUser) {
  sendAccessTokenToApp({
    accessToken: initUser.accessToken,
    memberId: initUser.memberId
  })
}

export const user = createSlice({
  name: 'user',
  initialState: {
    user: initUser
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      if (state.user) {
        sendAccessTokenToApp({
          accessToken: state.user.accessToken,
          memberId: state.user.memberId
        })
      } else {
        removeAccessTokenFromApp()
      }
      if (state.user && !state.user.$jid) {
        state.user.$jid = state.user.memberId + '@' + process.env.REACT_APP_EJ_HOST
      }
      localStorage.setItem(FIELD_NAME, JSON.stringify(action.payload))
    },
    setToken: (state, action) => {
      if (!state.user) {
        state.user = {
          memberId: '',
          accessToken: '',
          isAdmin: false,
          canBroadcast: false,
        }
      }
      changeToken(action.payload)
      state.user.token = action.payload
      localStorage.setItem(FIELD_NAME, JSON.stringify(state.user))
    },
    setAdditionalFields(state, {payload}) {
      state.user = {
        ...state.user,
        ...payload
      }
      localStorage.setItem(FIELD_NAME, JSON.stringify(state.user))
    }
  }
})

export const {setUser, setToken, setAdditionalFields} = user.actions
export const getUser = (state: RootState) => state.user.user

export default user.reducer
