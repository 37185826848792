import {Route, Routes} from "react-router-dom";
import Login from "../components/Auth/Login/Login";
import YouChat from "../pages/YouChat/YouChat";
import React, {useCallback, useEffect} from "react";
import PrivateRoute from "./PrivateRoute";
import {useAppDispatch, useAppSelector} from "@/hooks/appHook";
import {getUser} from "@/store/user/user";
import {setSize as setScreenSize} from '@/store/screenSize/screenSize';
import {getLang} from "@/store/language/language";

const Router = () => {
  const isLoggedIn = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const lang = useAppSelector(getLang)

  const updateScreenSize = useCallback(() => {
    dispatch(setScreenSize({
      width: window.innerWidth || document.documentElement.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight
    }))
  }, [dispatch])

  useEffect(() => {
    updateScreenSize()
    window.addEventListener('resize', updateScreenSize)
    return () => {
      window.removeEventListener('resize', updateScreenSize)
    }
  }, [updateScreenSize])

  return (
    <Routes>
      <Route path="/:langParam?" element={<PrivateRoute redirectPath={"/"+lang+"/login"} isLoggedIn={!!isLoggedIn}>
        <YouChat lang={lang} />
      </PrivateRoute>}/>
      <Route path="/:langParam?/login" element={<Login isLoggedIn={!!isLoggedIn} lang={lang}/>}/>
      <Route path="/:langParam?" element={<Login isLoggedIn={!!isLoggedIn} lang={lang}/>}/>
    </Routes>
  )
}

export default Router
