import request from "./request";

interface ILoginData {
  captcha: string,
  memberId: string,
  password: string,
  token: string
}

export const login = (userData: ILoginData) => {
  return request('post', '/api/users/login', userData)
}

interface ISsoData {
  memberId: string,
  otp: string
}
export const loginBySso = (data: ISsoData) => {
  return request('post', '/api/users/login-by-otp', {
    ...data
  })
}

interface IAuthData {
  accessToken: string,
  memberId: string,
}

export const AUTHENTICATE_PATH = '/api/users/authenticate'

export const authenticate = (authData: IAuthData) => {
  return request('post', AUTHENTICATE_PATH, authData)
}

interface CaptchaResponse {
  data: string,
  token: string
}

export const getCaptcha = (): Promise<CaptchaResponse> => {
  return request('post', '/api/users/captcha')
    .then(data => {
      return data.data
    })
}

interface ILoginAsMember {
  memberId: string
}

export const loginAsMember = ({memberId}:ILoginAsMember) => {
  return request('post', '/api/users/login-as-member', {memberId})
    .then(data => {
      return data.data
    })
}
